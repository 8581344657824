@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cabin:ital@1&family=Inknut+Antiqua:wght@300&family=Open+Sans:ital,wght@0,400;1,300&family=Oswald:wght@200&family=Poppins:ital,wght@0,200;0,400;1,500&family=Roboto+Condensed&family=Roboto:wght@100;300&family=ZCOOL+QingKe+HuangYou&display=swap);
/*
* Slot machine design inspired by Flaticon
* model url: https://www.flaticon.com/free-icon/slot-machine_536079#term=slot%20machine&page=1&position=46
*/
body {
  font-family: "Roboto Slab", serif;
  /* margin: auto; */
  /* background-color: black; */
  background: linear-gradient(74deg, rgb(36, 19, 47) 0%, rgba(27,15,35,1) 100%);
}
.Machine {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-direction: column;
  height: 330px;
  width: 300px;
  background-color: #37B4B5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 20vh auto 5vh auto;
  position: relative;
}

.Machine::before {
  content: "";
  position: absolute;
  width: 27px;
  height: 330px;
  background-color: #37B4B5;
  left: -20px;
  border-top-left-radius: 8px;
  z-index: -20;
}

.Machine-bottom {
  background-color: #F8EDB0;
  width: 320px;
  height: 30px;
  margin: auto;
  margin-top: -31px;
  margin-bottom: 30px;
  border-radius: 5px;
  bottom: -30px;
  position: absolute;
  right: -15px;
}

.Machine-bottom::before {
  position: absolute;
  content: "";
  left: -40px;
  bottom: 0;
  background-color: #F8EDB0;
  height: 30px;
  width: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: -10;
}

.Lights {
  position: absolute;
  top: 0;
}

.Light-bulb {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  margin: 20px 5px;
  box-shadow: #eaeaea 0px 1px 5px;
  -webkit-animation: toYellow 1s infinite;
          animation: toYellow 1s infinite;
}
.Light-bulb:nth-child(2n) {
  background-color: #f7b647;
  box-shadow: #f7b647 0px 1px 5px;
  -webkit-animation: toWhite 1s infinite;
          animation: toWhite 1s infinite;
}

.Reels {
  background-color: #f7b647;
  width: 290px;
  padding: 20px 30px;
  border-radius: 8px;
  position: relative;
}

.Reels::before {
  content: "";
  position: absolute;
  width: 27px;
  height: 100%;
  background-color: #f9a02a;
  left: -20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: -2;
  margin-top: -20px;
}

.Reel {
  display: inline-block;
  font-size: 58px;
  background-color: #eaeaea;
  border: #e3ed5a solid 0.5rem;
  border-radius: 5px;
  max-width: 80px;
  height: 80px;
  text-align: center;
  box-shadow: inset #3336 0px 1px 2px 1px;
  overflow: hidden;
}

.Reel-plate .span {
  display: block;
}

.Reel-plate-1 {
  -webkit-animation: slideDown 3s;
          animation: slideDown 3s;
}

.Reel-plate-1.spin {
  -webkit-animation: spin 0.1s linear infinite;
          animation: spin 0.1s linear infinite;
  /* 0.2s ease-out 0s 20; */
}

.Reel-plate-2 {
  -webkit-animation: slideDown 3s;
          animation: slideDown 3s;
}

.Reel-plate-2.spin {
  -webkit-animation: spin 0.1s linear infinite;
          animation: spin 0.1s linear infinite;
  /* 0.2s ease-out 0s 20; */
}

.Reel-plate-3 {
  -webkit-animation: slideDown 3s;
          animation: slideDown 3s;
}

.Reel-plate-3.spin {
  -webkit-animation: spin 0.1s linear infinite;
          animation: spin 0.1s linear infinite;
  /* 0.2s ease-out 0s 20; */
}

.Slot {
  position: absolute;
  bottom: 45px;
  left: 15px;
  background-color: #eaf2ff;
  border-radius: 8px;
  padding: 15px 13px;
}

.Slot-inner {
  background-color: #175dc6;
  height: 40px;
  width: 15px;
  border-radius: 8px;
}
.c_logo img{
  max-width: 250px;
  width:100%;
}
button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #D5339A;
  margin-right: 1rem;
  border-radius: 5px;
  color: #fff;
  font-size: 25px;
  font-family: 'Bebas Neue', cursive;
  box-shadow: -5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A;
  border: 1px solid #D5339A;
  text-shadow: black 1px 1px 2px;
  transition: all 0.3s ease-in-out;
  outline: none;
}
@media screen and (max-width: 480px) {
  button {
    font-size: 20px;
    padding: 5px 10px;
    margin-right: 0rem;
  }
  .c_logo img{
    max-width: 190px;
    width: 100%;
  
  }
}

button:hover,
button:focus {
  cursor: pointer;
  box-shadow: -2px 1px 0px 1px #67F1EE, -2px 2px #67F1EE, -2px 2px #67F1EE;
  outline: none;
}

button:focus {
  border: 1px solid #af200a;
}

button:disabled,
button[disabled] {
  border: 1px solid #d3867b;
  background-color: #fba092;
  box-shadow: -2px 1px 0px 1px #d3867b, -2px 2px #d3867b, -2px 2px #d3867b;
  cursor: not-allowed;
  outline: none;
}

.Message {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.Alarm-container {
  position: absolute;
  top: -75px;
}

.Alarm {
  background-color: #FD48E3;
  height: 76px;
  width: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
}

.Alarm::before {
  position: absolute;
  content: "";
  height: 76px;
  width: 35px;
  bottom: 0;
  left: -15px;
  background-color: #FD48E3;
  border-top-left-radius: 20px;
  z-index: -5;
}

.Alarm::after {
  background-color: #175dc6;
  height: 20px;
  width: 120px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
  position: absolute;
  content: "";
  bottom: 0;
  left: -27px;
}

.Alarm-bulb {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #FFEBA6;
  position: absolute;
  bottom: 10px;
  left: 15px;
}

.Alarm.alert {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
}

.Alarm.alert .Alarm-bulb {
  -webkit-animation: toYellow 1s infinite;
          animation: toYellow 1s infinite;
}

.Message {
  position: absolute;
  bottom: -120px;
  font-size: 26px;
  opacity: 0;
  width: 350px;
}

.Message-visible {
  opacity: 1;
  transition: opacity 4s ease-in;
}

@-webkit-keyframes toYellow {
  from {
    background-color: white;
    box-shadow: #eaeaea 0px 1px 5px;
  }
  to {
    background-color: #f7b647;
    box-shadow: #f7b647 0px 1px 5px;
  }
}

@keyframes toYellow {
  from {
    background-color: white;
    box-shadow: #eaeaea 0px 1px 5px;
  }
  to {
    background-color: #f7b647;
    box-shadow: #f7b647 0px 1px 5px;
  }
}

@-webkit-keyframes toWhite {
  from {
    background-color: #FF08FD;
    box-shadow: #53F2F4 0px 1px 5px;
  }
  to {
    background-color: #281841;
    box-shadow: #FFEBA6 0px 1px 5px;
  }
}

@keyframes toWhite {
  from {
    background-color: #FF08FD;
    box-shadow: #53F2F4 0px 1px 5px;
  }
  to {
    background-color: #281841;
    box-shadow: #FFEBA6 0px 1px 5px;
  }
}

@-webkit-keyframes pulse {
  from {
    background-color: #f84f35;
  }
  to {
    background-color: #ea2f12;
  }
}

@keyframes pulse {
  from {
    background-color: #f84f35;
  }
  to {
    background-color: #ea2f12;
  }
}

@-webkit-keyframes slideDown {
  from {
    margin-top: -150px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes slideDown {
  from {
    margin-top: -150px;
  }
  to {
    margin-top: 0;
  }
}

@-webkit-keyframes spin {
  0% {
    margin-top: -240px;
  }
  30% {
    margin-top: -200px;
  }
  50% {
    margin-top: -160px;
  }
  70% {
    margin-top: -80px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}

@keyframes spin {
  0% {
    margin-top: -240px;
  }
  30% {
    margin-top: -200px;
  }
  50% {
    margin-top: -160px;
  }
  70% {
    margin-top: -80px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}

@-webkit-keyframes pulsePrize {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulsePrize {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D7329A; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
